import DOMPurify from "dompurify";

const shortMonths = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

export function getTodayDateString()  {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function getTomorrowDateString() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  // Format tomorrow's date as "YYYY-MM-DD" (compatible with the 'date' input type)
  return tomorrow.toISOString().split('T')[0];
}

export function formatDate(dateString = '', format = 'short', country: 'us') {
  if (dateString.length === 0) return '';

  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  // Function to add ordinal suffix to day (e.g., 1st, 2nd, 3rd, 4th, ...)
  function getOrdinalSuffix(number: number) {
    const j = number % 10;
    const k = number % 100;
    if (j === 1 && k !== 11) {
      return number + 'st';
    }
    if (j === 2 && k !== 12) {
      return number + 'nd';
    }
    if (j === 3 && k !== 13) {
      return number + 'rd';
    }
    return number + 'th';
  }

  if (format === 'mtn_yr') {
    return `${month} ${year}`;
  } else if (format === 'short') {
    const monthShort = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const dayShort = String(day).padStart(2, '0'); // Add leading zero if needed
    if (country === 'us') {
      return `${year}-${monthShort}-${dayShort}`;
    } else {
      return `${dayShort}-${monthShort}-${year}`;
    }
  } else if (format === 'human') {
    return `${getOrdinalSuffix(day)} ${month} ${year}`;
  } else {
    return '';
  }
}

export function getFormattedMonth(month: number) {
  return shortMonths[month - 1];
}

export function convertTextToHTML(text: string) {
  const paragraphs = text.split('\n\n').map(paragraph => `<p>${paragraph}</p>`).join('');
  const withLineBreaks = paragraphs.replace(/\n/g, '<br>');
  return DOMPurify.sanitize(withLineBreaks);
}

export function getEstimatedReadingTime(content: string) {
  const wordCount = content.trim().split(/\s+/).length;
  const averageReadingSpeed = 200; // Adjust this value if needed
  return Math.ceil(wordCount / averageReadingSpeed);
}
